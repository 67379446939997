import "./sidebar.css";
import {
    LineStyle,
    Timeline,
    PermIdentity,
    Storefront,
    AttachMoney,
    Add,
    ShoppingCart,
    Assessment,
    ReportOutlined,
    QueryBuilder,
    Flag,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const hq = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.email === "admin@ssrmotorsport.com"
            : null
    );

    if (!hq) {
        return (
            <div className="sidebar">
                <div className="sidebarWrapper">
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Dashboard</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/"
                                className="link"
                            >
                                <li className="sidebarListItem active">
                                    <LineStyle className="sidebarIcon" />
                                    Home
                                </li>
                            </Link>
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/pos"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Timeline className="sidebarIcon" />
                                            POS
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/orders"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AttachMoney className="sidebarIcon" />
                                            Orders
                                        </li>
                                    </Link>

                                  
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Users</h3>
                        <ul className="sidebarList">
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/users"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <PermIdentity className="sidebarIcon" />
                                            Users
                                        </li>
                                    </Link>
                                </>
                            )}
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newuser"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New User
                                        </li>
                                    </Link>
                                </>
                            )}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/history"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Customer History
                                </li>
                            </Link>
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        {(superadmin || admin || marketing) && (
                            <h3 className="sidebarTitle">Products</h3>
                        )}
                        <ul className="sidebarList">
                            {(superadmin || admin || marketing) && (
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/products"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Storefront className="sidebarIcon" />
                                        Products
                                    </li>
                                </Link>
                            )}
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newproduct"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New Product
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        hq && (
            <div className="sidebar">
                <div className="sidebarWrapper">
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Dashboard</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/"
                                className="link"
                            >
                                <li className="sidebarListItem active">
                                    <LineStyle className="sidebarIcon" />
                                    Home
                                </li>
                            </Link>
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/pos"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Timeline className="sidebarIcon" />
                                            POS
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/orders"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AttachMoney className="sidebarIcon" />
                                            Orders
                                        </li>
                                    </Link>

                                   
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Users</h3>
                        <ul className="sidebarList">
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/users"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <PermIdentity className="sidebarIcon" />
                                            Users
                                        </li>
                                    </Link>
                                </>
                            )}
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newuser"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New User
                                        </li>
                                    </Link>
                                </>
                            )}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/history"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Customer History
                                </li>
                            </Link>
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        {(superadmin || admin || marketing) && (
                            <h3 className="sidebarTitle">Products</h3>
                        )}
                        <ul className="sidebarList">
                            {(superadmin || admin || marketing) && (
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/products"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Storefront className="sidebarIcon" />
                                        Products
                                    </li>
                                </Link>
                            )}
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newproduct"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New Product
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                {(superadmin || marketing) && (
                    <h3 className="sidebarTitle">Reports</h3>)}
                    <ul className="sidebarList">
                        {superadmin && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/productreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Assessment className="sidebarIcon" />
                                        Report Analysis
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/modelreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <ReportOutlined className="sidebarIcon" />
                                        Model Report
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/report"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <QueryBuilder className="sidebarIcon" />
                                        Monthly Report
                                    </li>
                                </Link>
                            </>
                        )}

                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/salesreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Flag className="sidebarIcon" />
                                        Outlet Report
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                </div>
            </div>
        )
    );
}
