import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProducts } from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import Select from "react-select";
import _ from "lodash";
import { publicRequest } from "../../requestMethods";

const statusOptions = [
    {
        label: "Inactive",
        value: "inactive",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "All",
        value: "all",
    },
];

const stockOptions = [
    {
        label: "Stock",
        value: true,
    },
    {
        label: "Out of Stock",
        value: false,
    },
];

export default function ProductList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );
    const products = useSelector((state) => state.product.products);
    const [filterCategory, setFilterCategory] = useState({
        status: "all",
        model: undefined,
        brand: undefined,
        category: undefined,
        stock: true,
    });
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState(undefined);

    const getAllBrands = async () => {
        const res = await publicRequest.get("/brand/all", {
            params: { isAdmin: true },
        });
        setBrands(res.data);
    };

    useEffect(() => {
        if (brands.length === 0) {
            getAllBrands();
        }
    }, [brands]);

    const getAllCategories = async () => {
        const res = await publicRequest.get("/category/all", {
            params: { isAdmin: true },
        });
        setCategories(res.data);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getAllCategories();
        }
    }, [categories]);

    useEffect(() => {
        getProducts(dispatch, filterCategory);
    }, [dispatch, filterCategory]);

    const handleDelete = (id) => {
        deleteProduct(id, dispatch);
    };

    const handleStock = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, stock: e.value };
        });
    };

    const handleStatus = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleBrand = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, brand: e.value };
        });
    };

    const handleCategory = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, category: e.value };
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (name !== undefined) {
                handleNameChange(name);
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [name]);

    const handleDelayedNameChange = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, name: e.target.value };
        });
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const columns = [
        {
            field: "brand",
            headerName: "Brand",
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">
                        {params.row.brand.name}
                    </div>
                );
            },
        },
        {
            field: "category",
            headerName: "Category",
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">
                        {params.row.category.name}
                    </div>
                );
            },
        },
        {
            field: "title",
            headerName: "Name",
            width: 300,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">{params.row.title}</div>
                );
            },
        },
        {
            field: "price",
            headerName: "Price",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">RM     {params.row.price}</div>
                );
            },
        },
        {
            field: "commission",
            headerName: "Commission",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">RM     {params.row.commission}</div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: 600,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={"/product/" + params.row._id}
                                >
                                    <button className="red productListEdit">
                                        Edit
                                    </button>
                                </Link>
                            </>
                        )}
                        {superadmin && (
                            <DeleteOutline
                                className="productListDelete"
                                onClick={() => handleDelete(params.row._id)}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className="productList">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="name"
                        value={name}
                        type="string"
                        placeholder="Name"
                        onChange={handleDelayedNameChange}
                    />
                </div>
                <div className="sameRowItem marginRight">
                    <Select
                        options={categories.map((status, i) => {
                            return {
                                label: status.name,
                                value: status._id,
                            };
                        })}
                        onChange={handleCategory}
                        placeholder="Search Category"
                    />
                </div>
                <div className="sameRowItem marginRight">
                    <Select
                        options={brands.map((status, i) => {
                            return {
                                label: status.name,
                                value: status._id,
                            };
                        })}
                        onChange={handleBrand}
                        placeholder="Search Brand"
                    />
                </div>
                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleStatus}
                        value={{
                            label: _.capitalize(filterCategory.status),
                            value: filterCategory.status,
                        }}
                        placeholder="Search Status"
                    />
                </div>

                <div className="sameRowItem marginRight">
                    <Select
                        options={stockOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleStock}
                        value={{
                            label: _.capitalize(filterCategory.stock),
                            value: filterCategory.stock,
                        }}
                        placeholder="Search Stock"
                    />
                </div>
            </div>

            <div className="block mt-4">
                {superadmin && (
                    <button
                        onClick={() => {
                            let payload = [];
                            products.forEach((product) => {
                                let formattedExcelColor = "";
                                let formattedExcelCategory = "";
                                product.color.map(
                                    (color) =>
                                        (formattedExcelColor =
                                            formattedExcelColor +
                                            "," +
                                            color.name)
                                );
                                product.categories.map(
                                    (category) =>
                                        (formattedExcelCategory =
                                            formattedExcelCategory +
                                            "," +
                                            category)
                                );
                                payload.push({
                                    ...product,
                                    formattedExcelColor:
                                        formattedExcelColor.slice(1),
                                    formattedExcelCategory:
                                        formattedExcelCategory.slice(1),
                                    price:
                                        process.env.REACT_APP_COUNTRY ===
                                        "SINGAPORE"
                                            ? product.price
                                            : product.myprice,
                                });
                            });
                            excel({
                                excelData: payload,
                                fileName: "HaofaProduct",
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                )}
            </div>

            <DataGrid
                rows={products}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={16}
                disableColumnMenu
            />
        </div>
    );
}
