import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "ssrcart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
    },
    reducers: {
        addProduct: (state, action) => {
            const product = action.payload
            const countryPrice = product.price
            state.quantity += 1;
            state.products.push({
                _id: product._id,
                title: product.title,
                color: product.activeColor,
                model: product.model,
                price: countryPrice,
                brand: product.brand.name,
                img: product.img,
                amount: 1
            });
            state.total += countryPrice * action.payload.quantity;
        },
        addQuantity: (state, action) => {
            const product = action.payload
            const countryPrice = product.price
            const existingProductIndex = state.color.findIndex(
                (item) => `${item.title}-${item.color.name}` === `${action.payload.title}-${action.payload.activeColor.name}`
            );
            state.color[existingProductIndex].quantity =
            state.color[existingProductIndex].quantity +
                action.payload.quantity;
                
            state.total = state.total + (countryPrice * action.payload.quantity);
            if (state.products[existingProductIndex].quantity <= 0){
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1
            }
        },
        addQuantityIcon: (state, action) => {
            const countryPrice = action.payload.price
            
            const existingProductIndex = state.products.findIndex(
                (item) => item.color.name === action.payload.activeColor.name
            );

            const existingProduct = state.products[existingProductIndex];
            if (existingProduct) {
                // Create a deep copy of the product and its color
                let updatedProduct = { ...existingProduct, color: { ...existingProduct.color } };
              
                if (updatedProduct.color && updatedProduct.color.name === action.payload.activeColor.name) {
                    // Update the amount in the copied object
                    updatedProduct.color.amount += action.payload.quantity;

            
              
                // Replace the existing product in the products array with the updated one
                state.products[existingProductIndex] = updatedProduct;
            }

            state.products[existingProductIndex].amount =
            state.products[existingProductIndex].amount +
                action.payload.quantity;
                }
            

            state.quantity = state.quantity + action.payload.quantity;

            state.total = state.total + (countryPrice * action.payload.quantity);
            if (state.products[existingProductIndex].amount <= 0){
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1
            }
        },
        clearCart: (state) => {
            state.products = [];
            state.quantity = 0;
            state.total = 0;
            state.warranty = [];
            state.order = [];
            state.affiliate = [];
          }
    },
});

export const { addProduct, addQuantity, addQuantityIcon, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
