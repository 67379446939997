import { useState } from "react";
import "./newUser.css";
import { register } from "../../redux/apiCalls";

export default function NewUser() {
    const [inputs, setInputs] = useState({});

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleClick = async (e) => {
        const user = { ...inputs };
        e.preventDefault();
        try {
            register({ user });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New User</h1>
                <div className="addProductForm">
                    <div className="addProductItem">
                        <label className="black required">Name</label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Email</label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Email"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Phone Number</label>
                        <input
                            name="phoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Password</label>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black">Address (optional)</label>
                        <input
                            name="lineOne"
                            type="string"
                            placeholder="Line One"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="lineTwo"
                            type="string"
                            placeholder="Line Two"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="zip"
                            type="string"
                            placeholder="Zipcode"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="city"
                            type="string"
                            placeholder="City"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="country"
                            type="string"
                            placeholder="Country"
                            onChange={handleChange}
                        />
                    </div>
                    <button onClick={handleClick} className="updateProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
