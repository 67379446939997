import React from "react";
import "./topbar.css";
import { logout } from "../../redux/userRedux";
import { ExitToApp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

export default function Topbar() {
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(logout());
        window.location.href = "/";
    };

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <a className="logo" href="/">
                        {process.env.REACT_APP_PROJECT === "HAOFA"
                            ? "Air Vitamin"
                            : "KLERP Demo System"}
                    </a>
                </div>
                <div className="topRight">
                    <div className="marginRightSmall">Welcome, {currentUser.currentUser.name}</div>
                    <div className="topbarIconContainer">
                        <div
                            style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                            }}
                            className={
                                currentUser.currentUser == null
                                    ? "hidden"
                                    : null
                            }
                            onClick={handleClick}
                        >
                            <div
                                className={
                                    currentUser.currentUser == null
                                        ? "hidden"
                                        : null
                                }
                            >
                                <ExitToApp></ExitToApp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
